<template>
    <v-container fluid class="pa-6">
        <v-row>
            <v-col md="12" lg="8">
                <TableComponent v-for="table in this.tables" :key="table.name" :table="table" class="mb-4" />
            </v-col>
            <v-col md="12" lg="4">
                <ExchangesComponent v-if="mission.id" :mission="mission" class="mb-4" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TableComponent from "@/modules/missions/missionsComponents/tableComponent";
import ExchangesComponent from "@/modules/missions/missionsComponents/exchangesComponent";

export default {
    name: "MissionDetailStakeholders",
    components: {
        TableComponent,
        ExchangesComponent,
    },
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {
        mission: function(){
            return this.$store.state.missions.mission
        },
        tables: function(){
            if(!this.mission.id)
                return [];

            let tables = [
                {
                    "name": "customers",
                    "apiUrl": "/mission/getCustomers?id=" + this.mission.id,
                },
                {
                    "name": "providers",
                    "apiUrl": "/mission/getProviders?id=" + this.mission.id,
                }
            ];

            if(this.mission.config.staff_qty) {
                tables.push({
                    "name": "assignedToTheMission",
                    "apiUrl": "/mission/getAssigned?id=" + this.mission.id
                })
            }

            return tables;
        }
    },
};
</script>

<style lang="scss">
</style>
