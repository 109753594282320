<template>
    <v-card>
        <v-card-title>
            <h2 class="icon-title mb-0" style="width:100%; align-items:center;">
                <span>
                    {{ $t("exchanges") }}
                </span>
                <span>
                    <v-btn small depressed color="primary" @click="getForm(exchangeFormUrl)">
                        <v-icon left dark small>$plus_l</v-icon>{{ $t('add') }}
                    </v-btn>
                </span>
            </h2>
        </v-card-title>

        <v-list two-line>
            <v-alert v-if="data && !data.length" class="mx-4" border="left" color="info" text>
                {{$t('emptyExchangesInfoMessage')}}
            </v-alert>
            <template v-else v-for="(item, key) in data">
                <v-list-item v-if="item.user_id === userLogged.profile.id" :key="key">
                    <v-list-item-content class="text-right">
                        <v-list-item-title>
                            <div class="exchange-text right">{{item.message}}</div>
                        </v-list-item-title>
                        <v-list-item-subtitle><small>{{parseFullDateToFr(item.date)}}</small></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar color="primary">
                        <v-img v-if="item.user.avatar && Object.keys(item.user.avatar).length !== 0" v-bind:src="item.user.avatar" />
                        <span v-else class="white--text">{{getInitials(item.user)}}</span>
                    </v-list-item-avatar>
                </v-list-item>
                <v-list-item v-else :key="key">
                    <v-list-item-avatar color="primary">
                        <v-img v-if="item.user.avatar && Object.keys(item.user.avatar).length !== 0" v-bind:src="item.user.avatar" />
                        <span v-else class="white--text">{{getInitials(item.user)}}</span>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <div class="exchange-text left">{{item.message}}</div>
                        </v-list-item-title>
                        <v-list-item-subtitle><small>{{parseFullDateToFr(item.date)}}</small></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        
        <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" />
    </v-card>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import dateManipulation from '@/mixins/dateManipulation';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins';

export default {
    name: "exchangesComponent",
    components: { FormDialogComponent },
    mixins:[dateManipulation, getForm],
    props: ["mission"],
    data() {
        return {
            data: null,
            form: null,
            exchangeFormUrl: "mission/getExchangeForm?id=" + this.mission.id,
        };
    },
    created() {
        this.loadExchanges();
    },
    watch: {},
    methods: {
        loadExchanges() {
            GenericDataService.getData("mission/getExchanges?id=" + this.mission.id).then((response) => {
                if(response.data.success){
                    this.data = response.data.data;
                }
            })
        },
        getInitials(item) {
            let initials = "";
            initials += item.firstname ? item.firstname.charAt(0) : "";
            initials += item.name ? item.name.charAt(0) : "";
            return initials;
        },
        customRefresh() {
            this.loadExchanges();
        }
    },
    computed: {
        userLogged() {
            return this.$store.state.auth.currentUser;
        },
    },
};
</script>

<style lang="scss">
    .exchange-text {
        color: rgba(0,0,0,.87); 
        padding: 10px 12px; 
        background-color: rgba(0, 0, 0, 0.05); 
        border-radius: 4px;
        text-align: left;
        white-space: initial;
        &.right {
            float: right; 
        }
        &.left {
            float: left; 
        }
    }
</style>